<template>
    <div class="my-accout">
        <div class="my-head">
            <h3 class="my-title">我的K点</h3>
        </div>
        <div class="my-body">
            <h3 class="k-title">我的可用K点</h3>
            <h4 class="k-title k-num">{{ kNum }}</h4>
            <h5 class="k-subtitle">K点使用明细</h5>
            <table class="table-my">
                <thead>
                    <tr>
                        <th>时间</th>
                        <th>K点变更</th>
                        <th>操作</th>
                        <th>备注</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in kList" :key="item.addtime">
                        <td>{{ item.addtime }}</td>
                        <td>{{ item.dintegral }}</td>
                        <td>{{ [ '客服操作K点', '订单使用抵扣k点', '订单退款K点' ][ +item.type - 1 ] }}</td>
                        <td><span v-if="['2', '3'].includes(item.type)">订单{{ item.orderid }}，{{['使用', '退单入账'][+item.type - 2]}}K点</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { integralList } from '@api/my'
// import '../mock'
export default {
    name: 'MyKPoint',
    data() {
        return {
            kNum: '0',
            kList: [],
            memo: [ '客服操作K点', '订单使用抵扣k点', '订单退款K点' ]
        }
    },
    created() {
        // 获取K点列表
        const self = this
        this.integralList()
        // 监听pageHeader转发的登录信息判断用户是否已登录
        // this.$bus.$on('userInfo', infoObj => {
        //     // console.log('infoObj', infoObj)
        //     if (infoObj && infoObj.integral) {
        //         this.kNum = infoObj.integral
        //     }
        // })
        // 第二次要从缓存取
        // if(Object.keys(this.userInfo).length === 0) {
        // const userInfoCache = sessionStorage.getItem('USER_INFO')
        // console.log('userInfoCache', userInfoCache)
        // if (userInfoCache) {
        //     try {
        //         self.kNum = JSON.parse(userInfoCache).integral
        //     } catch (err) {
        //         console.log('err', err)
        //     }
        // }
        // }
    },
    methods: {
        async integralList() {
            const res = await integralList()
            console.log('integralList res', res)
            const list = res.list
            if (res.code === 1001) {
                this.kList = res.list.list
                this.kNum = this.kList[0] ? this.kList[0].now_integral : 0
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
}
.my-body {
    min-height: 1000px;
    position: relative;
    padding: 20px;
    background-color: $white;
}
.k-title {
    margin-bottom: 10px;
    font-size: 16px;
}
.k-num {
    margin-bottom: 15px;
    height: 36px;
    font-size: 28px;
    color: $red;
}
.k-subtitle {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $lighter;
    font-weight: normal;
    font-size: 14px;
}
</style>
